@import "./variables.scss";

/*
* Icons
*/

.red-icon > svg > path {
    fill: $red;
}

.green-icon > svg > path {
    fill: $green;
}

.blue-icon > svg > path {
    fill: $blue;
}

.orange-icon > svg > path {
    fill: $orange;
}

.action-bar {
    top: 48px !important;
}

/*
* Standard elements
*/

html,
body,
app-root {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 14px;
}

body {
    padding-top: 52px;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: "Roboto", sans-serif;
    background-color: #edecec;
}

label {
    font-size: $font-size !important;
    line-height: 1.2 !important;
}

input.ng-dirty.ng-invalid {
    border-color: $red !important;
}

textarea {
    line-height: 1.5 !important;
    overflow-y: scroll !important;
}

/*
 * Loading animation
 */

.sk-rotating-plane {
    background-color: $accent !important;
    width: 60px !important;
    height: 60px !important;

    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*
 * Progress bar animations
 */
@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

/*
 * Scrollbar
 */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #e6e6e6;
}

::-webkit-scrollbar-thumb {
    background: $accent;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.navbar span.toggle-menu {
    color: $white;
    cursor: pointer;
    font-size: 1.5rem;
    opacity: 0.7;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    &:hover {
        opacity: 1;
    }
}

/*
* Draggable grid rows
*/

app-grid {
    .draggable tbody td {
        cursor: move !important;
    }

    .is-dragging {
        background-color: #c6e2ff !important;
        border: 1px dashed $black !important;
    }

    .is-dropping {
        background-color: #b9f9c7 !important;
        border: 1px dashed $black !important;
    }

    td,
    app-grid .k-grid-aria-root {
        overflow: hidden !important;
    }
}
.k-pager-info {
    display: flex !important;
}

/*
* General overwrites & styles
*/

.xxl-modal .modal-dialog {
    max-width: 1700px;
}

.extra-large-modal > .modal-dialog {
    max-width: 100vw;
    min-width: 65vw;
    width: 95%;
}

.modal-backdrop {
    z-index: 900 !important;
}

.add-extra,
.batch-errors {
    & > kendo-grid > div > table > thead {
        display: none;
    }
}

.voyage-summary-table {
    .k-grid-aria-root {
        overflow: visible !important;
    }

    .summary-row {
        background-color: #f7f7f7;
        text-align: right;
        position: sticky;
        bottom: -16px;
    }

    td {
        font-size: 12px;
    }
}

/*
* Utility
*/

.under-action-bar {
    z-index: 98;
}

.pointer {
    cursor: pointer;
}

.bold {
    &,
    * {
        font-weight: bold !important;
    }
}

.view-title h1 {
    text-transform: uppercase !important;
}

.simple-container {
    $font-size: 1rem;

    display: inline;
    border: 1px solid $border-color;
    border-radius: calc($font-size * 0.25);
    padding: calc($font-size * 0.25);
}

.link-box {
    display: inline-block;
    height: 100%;
    width: 100%;
    line-height: $font-size;
    height: $font-size;
    cursor: pointer;
}

.box-shadow {
    box-shadow: 3px 3px 4px rgba($black, 0.2);

    &.strong {
        box-shadow: 3px 3px 4px rgba($black, 0.4);
    }

    &.tight {
        box-shadow: 2px 2px 2px rgba($black, 0.3);
    }
}

.filter-wrapper {
    $base-unit: 0.225rem;
    $font-size: calc($base-unit * 5);
    $larger: calc($base-unit * 6);
    $spacing: calc($base-unit * 3);

    background-color: $white;
    border-radius: $border-radius;

    .accordion-item,
    .accordion-item .accordion-header-container {
        border-radius: $border-radius !important;
    }

    .accordion-item {
        border: 1px solid $border-color;
        border-top: 3px solid $accent;

        .accordion-body {
            border-top: 1px solid $border-color;
            padding: 20px;
        }

        .accordion-header-container {
            border-radius: $border-radius;

            .accordion-title {
                height: fit-content;

                h5 {
                    font-size: $font-size;
                    margin: $spacing 0 $spacing $spacing;
                }
            }

            .chevron {
                margin-right: $larger;

                fa-icon {
                    font-size: $larger;
                    color: $accent;
                }
            }
        }
    }
}

/*
* Theme overwrites
*/

/*
 * Input and form
 */

kendo-uploaddropzone {
    .k-i-upload,
    .k-dropzone-hint,
    .k-dropzone-k {
        display: none;
    }

    .k-dropzone-note {
        width: 100%;
    }
}

kendo-multiselect .k-multiselect-wrap {
    overflow: hidden;
}

kendo-multiselect.is-read-only {
    .k-chip-list {
        cursor: default;
    }

    .k-chip {
        pointer-events: none;

        .k-chip-action {
            display: none;
        }
    }
}

.k-autocomplete,
.k-combobox,
.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-dropdown,
.k-multiselect,
.k-numerictextbox,
.k-maskedtextbox,
.k-timepicker,
.k-dropdowntree {
    font-size: $font-size;
}

.k-textbox {
    padding-left: 0 !important;
}

.k-dropdownlist {
    border-color: $border-color;

    &:focus {
        border-color: $input-focus-color;
    }

    &:focus-visible {
        outline: none;
    }
}

.k-breadcrumb {
    padding: 0;
    background-color: transparent !important;
}

.form-control.k-input,
.form-control.k-input:focus {
    height: $input-height !important;
    border-radius: $border-radius;

    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;

    transition: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;

    > .k-input,
    > .k-input:focus {
        box-shadow: none;
    }

    .k-button:not(.k-spinner-increase):not(.k-spinner-decrease) {
        border-radius: 0 $border-radius $border-radius 0 !important;
    }

    .k-spinner-increase {
        border-radius: 0 $border-radius 0 0 !important;
    }

    .k-spinner-decrease {
        border-radius: 0 0 $border-radius 0 !important;
    }

    &.disabled,
    &.is-read-only {
        background-color: $input-disabled-color;

        .k-input-inner {
            background-color: $input-disabled-color;
        }

        .k-button {
            visibility: hidden;
        }
    }
}

.k-disabled {
    opacity: 1 !important;
    background-color: #e9ecef !important;
    color: black !important;
}

.k-input-suffix {
    pointer-events: all !important;
}

.form-control-sm {
    .k-multiselect-wrap .k-button {
        min-height: calc(1.2em + 0.25rem + 2px + 2px);
        padding: calc(0.1rem + 1px) 0.275rem;
    }

    .k-autocomplete .k-clear-value,
    .k-dropdown-wrap .k-clear-value,
    .k-multiselect-wrap .k-clear-value {
        height: 1.2rem;
        line-height: 1.2rem;
    }

    .k-multiselect-wrap {
        height: 32px;

        li.k-button {
            min-height: 1rem;
            line-height: 1rem;
            margin: 0.19rem 0 0 0.19rem;
        }

        .k-select {
            width: 1rem;
        }
    }
}

.form-control.k-widget {
    border: 0;
}

.form-control-sm.k-widget .k-input {
    padding: 0.25rem 0.5rem;
    height: 1.8125rem;
}

// Kendo DateTime selector popup
.k-datetime-container .k-datetime-calendar-wrap,
.k-datetime-container .k-datetime-time-wrap {
    flex: 0 0 27em;
    height: 320px;
    overflow: hidden;
}

.k-datetime-container .k-datetime-wrap {
    width: 27em !important;

    .k-calendar-header {
        margin-top: 14px !important;

        .k-button:hover {
            color: $font-color;
        }
    }

    .k-datetime-buttongroup .k-button:first-child {
        margin-right: 14px !important;
    }

    .k-datetime-footer {
        margin-top: 0 !important;
    }
}

.k-clear-value {
    color: #6e6e6e !important;

    &.date-time-close {
        margin-right: 3px;
    }
}

.k-list .k-item:focus:hover,
.k-list .k-item:focus.k-hover,
.k-list .k-item.k-focused:hover,
.k-list .k-item.k-focused.k-hover,
.k-list-optionlabel:focus:hover,
.k-list-optionlabel:focus.k-hover,
.k-list-optionlabel.k-focused:hover,
.k-list-optionlabel.k-focused.k-hover {
    box-shadow: inset 0 0 0 1px rgba(22, 24, 27, 0.1);
}

.k-list .k-item:focus,
.k-list .k-item.k-focused,
.k-list-optionlabel:focus,
.k-list-optionlabel.k-focused {
    box-shadow: inset 0 0 0 1px rgba(33, 37, 41, 0.1);
}

/*
 * Grid
 */

table.table-sm {
    font-size: 13px;

    th {
        font-weight: normal;
    }
}

kendo-grid {
    .k-checkbox-label {
        padding: 20px;
        margin: -9px -9px;
    }

    .k-checkbox-label::after,
    .k-checkbox-label::before {
        margin-top: 10px;
        margin-left: 10px;
    }

    th {
        overflow: visible !important;
    }
}

.k-grid,
.k-grid-table {
    &.editable {
        $data-cell-padding: 4px;

        td,
        th {
            height: calc($input-height + $data-cell-padding * 2);
        }

        margin: 0;

        td {
            padding: $data-cell-padding !important;
        }

        th {
            padding: 0.25rem 0.5rem !important;
        }
    }

    td,
    th {
        padding: $table-cell-padding !important;
        text-align: center !important;
        justify-content: center !important;
    }

    th.k-sorted {
        background-color: $brand-primary !important;
    }

    th {
        vertical-align: middle !important;
        font-weight: 500 !important;

        &.bg-danger {
            border: none;
        }

        // Wrap header titles
        &.k-header {
            overflow: visible;
            white-space: pre-wrap;
            word-wrap: normal;
            background-color: $brand-primary;
            color: white !important;
            // pointer-events: none; // Prevent table header highligting
        }

        .k-cell-inner {
            margin: 0;
            max-width: 100%;
            padding: 0.5rem 0rem !important;

            .k-link {
                overflow: visible;
                white-space: pre-wrap;
                word-wrap: normal;
                padding: 0.5rem 0rem !important;

                .k-column-title {
                    max-width: 85%;
                    overflow: visible;
                    white-space: pre-wrap;
                    word-wrap: normal;
                }

                .k-icon {
                    position: absolute;
                    right: 0;
                }
            }
        }

        .k-checkbox {
            vertical-align: middle !important;
        }
    }

    tr button {
        margin: 0;
        padding: 2px 4px;
        font-size: 12px;
    }

    tr.k-detail-row > td.k-hierarchy-cell {
        border-left: 3px solid $accent-secondary !important;
    }

    tr {
        td {
            &.k-detail-cell {
                padding-left: 0;
            }

            &.k-hierarchy-cell:not(.ng-star-inserted) {
                border-left: 0;
            }

            &.k-detail-cell {
                border-top: 1px;
                border-bottom: 1px;
                border-style: solid;
                border-color: $border-color;
            }

            .k-checkbox {
                vertical-align: middle;
            }

            .checkbox-container {
                text-align: center;
            }

            a.k-icon {
                display: flex !important;
                align-items: center !important;
            }
        }

        &:last-child td {
            &.k-detail-cell,
            &.k-hierarchy-cell:not(.ng-star-inserted) {
                border-bottom: 0;
            }
        }

        &.k-master-row {
            &:not(.k-selected),
            &:not(.k-selected):hover {
                td {
                    background-color: $k-table-stripe;
                }

                &.k-alt td {
                    background-color: $k-table-alt-stripe;
                }
            }

            &.k-selected,
            &.k-selected:hover {
                td {
                    background-color: $k-table-selected-stripe;
                }

                &.k-alt td {
                    background-color: $k-table-alt-selected-stripe;
                }
            }
        }

        .k-command-cell .k-button {
            margin: 0 !important;
        }
    }

    .k-grid-header tr:hover {
        background-color: $k-table-header !important;
    }

    .k-toolbar {
        $spacing: 0.5rem;

        display: block;
        border: none;
        padding: $spacing;

        button:not(:last-of-type) {
            margin-right: $spacing;
        }

        button:first-of-type {
            margin-left: $spacing;
        }

        &[position="top"] {
            border-bottom: 1px solid $border-color;
        }

        &[position="bottom"] {
            border-top: 1px solid $border-color;
        }
    }
}

.k-grid-md .k-table-th > .k-cell-inner {
    margin: -1rem 0rem !important;
}

.k-pager-wrap:focus {
    box-shadow: none !important;
}

/*
 * Button
 */

button {
    border-radius: $btn-border-radius !important;
    box-shadow: none !important;

    &.btn-xs,
    &.btn-group-xs > .btn {
        padding: calc($font-size * 0.25) calc($font-size * 0.5) !important;
        font-size: $font-size;
        line-height: calc($font-size * 1.25);
        border-radius: $btn-border-radius !important;
    }

    &.btn-sm,
    &.btn-group-sm > .btn {
        padding: calc($font-size * 0.25) calc($font-size * 0.5) !important;
        font-size: $font-size;
        line-height: calc($font-size * 1.5);
        border-radius: $btn-border-radius !important;
    }

    &.btn-primary {
        background-color: $brand-primary !important;
        color: $white !important;
    }

    &.btn-danger {
        background-color: $red !important;
        color: $white;
    }
}

.k-button {
    cursor: pointer !important;
}

/*
* Tabstrip
*/

.k-tabstrip {
    > .k-content {
        padding: 20px !important;
        border-top: 1px solid;
        border-color: $border-color !important;
        border-bottom-left-radius: 0.5rem !important;
        border-bottom-right-radius: 0.5rem !important;
        outline: none !important;
    }

    .k-item {
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
        box-shadow: none !important;
        user-select: none;
        background: white !important;

        &.k-active {
            border-top: 3px solid;
            border-top-color: $accent !important;

            .k-link {
                position: relative;
                top: -1px;
            }
        }

        &.hidden-tab {
            display: none;
        }

        &:hover:not(.k-active) {
            border-bottom: 1px solid $border-color;
        }
    }
}

.k-tabstrip-items-wrapper {
    .k-item.k-active {
        background: $brand-primary !important;
        color: white !important;
        font-weight: bolder !important;
    }

    .k-item:hover {
        background: $brand-primary-selected !important;
        color: white !important;
    }

    .k-item {
        border: 0.1px solid rgb(207, 201, 201) !important;
    }
}

/*
* Modal
*/

.modal-content,
.k-dialog {
    border-radius: $border-radius;
    border: 1px solid $border-color;
}

.k-dialog-content {
    overflow-x: hidden !important;
}

.k-dialog-actions {
    margin-left: 1px;
    margin-bottom: 5px;
    justify-content: end;
    padding-right: 20px !important;
}

kendo-splitter {
    background-color: #edecec !important;
    border: 0 !important;
}

/*
* Card
*/

.card-header-tabs > .nav-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.card-border-top {
    border-top-width: 3px;
    border-top-color: $accent !important;
}

.card {
    border-radius: $border-radius;
    border-color: $border-color;
}

/*
* Accordion
*/

ngb-accordion .card-header {
    padding: 0px !important;
}

/*
* Misc.
*/

ngb-popover-window,
.toast-container > .ngx-toastr {
    border-radius: $border-radius !important;
    z-index: 1051 !important;
}

.k-appbar {
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, $white 0%, $white 40%, $brand-primary 70%);

    .k-button.hamburger > fa-icon {
        color: $brand-primary !important;
    }
}

h4.card-title {
    padding: 10px;
    color: $brand-primary;
}
