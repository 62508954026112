$theme-colors: (
    dark: #1e282c
);

$table-cell-padding: 0.5rem;

// Font
$font-size: 1rem;
$font-size-sm: calc($font-size * 0.875);
$font-color: #000;

// Inputs
$input-height: 34px;
$input-label-font-weight: 400;
$input-disabled-color: #e9ecef;
$input-focus-color: #86b7fe;

// Borders
$card-border-radius: 0;
$border-radius: 0.5rem;
$border-radius-lg: 0;
$border-radius-sm: 0.125rem;
$btn-border-radius: 0.333rem;
$dropdown-border-radius: 0;
$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

// Colors
$brand-primary: #012f57 !default;
$brand-primary-selected: #0e4678;
$secondary: #ccc !default;
$border-color: #dee2e6 !default;

$white: #fff;
$grey: #b8c7ce;
$grey2: #4b646f;
$grey3: #2c3b41;
$black: #000;

$red: #dc3545 !default;
$green: #2cda43 !default;
$blue: #007bff !default;
$orange: #E6772E !default;

$action-primary: $white !default;
$action-secondary: rgb(202, 202, 202) !default;

$accent: $brand-primary !default;
$accent-secondary: #7fbdff !default;

// k-table colors
$k-table-header: #f8f9fa;
$k-table-stripe: #fff;
$k-table-selected-stripe: #c2dbfe;
$k-table-alt-stripe: #f2f2f2;
$k-table-alt-selected-stripe: #bbd4f7;
